import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    container,
    top,
    bottom,
    logo,
    socials,
    social,
    apLink,
    created,
} from './footer.module.scss';
import { grid } from '../../styles/grid.module.scss';

import FacebookIcon from '../../assets/images/soplica-wedel/svg/facebook.svg';

import Logo from '../../components/atoms/logo';

interface IFooterProps {
    className?: string;
}

const Footer: React.FC<IFooterProps> = ({ className = '' }) => {
    const { t } = useI18next();

    return (
        <footer className={`${container} ${grid} ${className}`}>
            <div className={top}>
                <Logo type="white" className={logo} />
                <div className={logo}>
                    <StaticImage
                        src="../../assets/images/soplica-wedel/logo-wedel.png"
                        alt="Wedel"
                        quality={100}
                    />
                </div>
            </div>
            <div className={bottom}>
                <p>
                    <span>{t('wedel.footer.1')}</span>
                    <br />
                    <span>{t('wedel.footer.2')}</span>
                </p>
                <div className={socials}>
                    <a
                        href="https://www.facebook.com/antek.soplica"
                        target="_blank"
                        rel="noreferrer noopener"
                        className={social}
                    >
                        <FacebookIcon />
                    </a>
                </div>
                <p className={created}>
                    Created with <strong>LOVE</strong> by{' '}
                    <a href="https://alterpage.pl/" className={apLink}>
                        Alterpage
                    </a>
                </p>
            </div>
        </footer>
    );
};

export default Footer;
