import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Swiper as SwiperCore } from 'swiper';
import 'swiper/scss';
import 'swiper/scss/navigation';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    container,
    box,
    inner,
    header,
    navigation,
    prev,
    next,
    title,
    sliderBox,
    button,
    chocolateSplash,
    caramelSplash,
    chocolate,
} from './serving.module.scss';
import { grid } from '../../styles/grid.module.scss';
import StarIcon from '../../assets/images/soplica-wedel/svg/star.svg';
import ArrowIcon from '../../assets/images/soplica-wedel/svg/arrow.svg';

import ServingCard, { IWedelServing } from './serving-card';
import Button from './button';
import AbsoluteImage from './absolute-image';

interface IServingProps {
    className?: string;
    servingCards: IWedelServing[];
    id?: string;
}

SwiperCore.use([Autoplay, Navigation]);

const Serving: React.FC<IServingProps> = ({ className = '', servingCards, id }) => {
    const { t } = useI18next();
    const prevRef = useRef<HTMLButtonElement>(null);
    const nextRef = useRef<HTMLButtonElement>(null);
    const [prevEl, setPrevEl] = useState<HTMLButtonElement | null>(null);
    const [nextEl, setNextEl] = useState<HTMLButtonElement | null>(null);

    useEffect(() => {
        setPrevEl(prevRef.current);
        setNextEl(nextRef.current);
    }, []);

    return (
        <div id={id} className={`${container} ${grid} ${className}`}>
            <div className={box}>
                <AbsoluteImage image="chocolate" className={chocolate} />
                <div className={inner}>
                    <AbsoluteImage image="chocolate-splash-two" className={chocolateSplash} />
                    <AbsoluteImage image="caramel-splash-two" className={caramelSplash} />
                    <div className={header}>
                        <StarIcon />
                        <h2 className={title}>
                            {t('wedel.serving.try')}
                            <br />
                            <span>{t('wedel.serving.ways')}</span>
                        </h2>
                    </div>
                    <div className={navigation}>
                        <button ref={prevRef} className={prev}>
                            <Button className={button} as="element">
                                <ArrowIcon />
                            </Button>
                        </button>
                        <button ref={nextRef} className={next}>
                            <Button className={button} as="element">
                                <ArrowIcon />
                            </Button>
                        </button>
                    </div>
                    <div className={sliderBox}>
                        <Swiper
                            centeredSlides={false}
                            modules={[Autoplay, Navigation]}
                            navigation={{ prevEl, nextEl }}
                            breakpoints={{
                                0: { slidesPerView: 1, spaceBetween: 12 },
                                832: { slidesPerView: 2, spaceBetween: 12 },
                            }}
                        >
                            {servingCards.map((servingCard, index) => {
                                return (
                                    <SwiperSlide key={`serving-slide-${index}`}>
                                        <ServingCard card={servingCard} />
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Serving;
