import React, { useState } from 'react';
import { Link } from 'gatsby';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { container, navigation, links, logo, link, hamburger, open } from './header.module.scss';
import { grid } from '../../styles/grid.module.scss';

import Logo from '../../components/atoms/logo';
import Hamburger from '../../components/atoms/hamburger';

interface IHeaderProps {
    className?: string;
}

const Header: React.FC<IHeaderProps> = ({ className = '' }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useI18next();

    const handleToggle = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <header className={`${container} ${grid} ${className} ${isOpen ? open : ''}`}>
            <nav className={navigation}>
                <Logo type="white" className={logo} />
                <Hamburger className={hamburger} isNavOpen={isOpen} onClick={handleToggle} />
                <div className={links}>
                    <Link className={link} to="#liquors">
                        {t('wedel.header.liquors')}
                    </Link>
                    <Link className={link} to="#serving">
                        {t('wedel.header.serving')}
                    </Link>
                    <Link className={link} to="#recipes">
                        {t('wedel.header.recipes')}
                    </Link>
                </div>
            </nav>
        </header>
    );
};

export default Header;
