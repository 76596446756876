import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { disclaimer, inner, text, withUnderlay } from './serving-disclaimer.module.scss';

interface IServingDisclaimerProps {
    className?: string;
    isWithUnderlay?: boolean;
}

const ServingDisclaimer: React.FC<IServingDisclaimerProps> = ({
    className = '',
    isWithUnderlay = false,
}) => {
    const { t } = useI18next();

    return (
        <p className={`${disclaimer} ${className} ${isWithUnderlay ? withUnderlay : ''}`}>
            <span className={inner}>
                <span className={text}>{t('wedel.serving.disclaimer')}</span>
            </span>
        </p>
    );
};

export default ServingDisclaimer;
