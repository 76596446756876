import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    container,
    icon,
    title,
    imageBox,
    chocolateSplash,
    caramelSplash,
    titleBox,
    chocolate,
    caramel01,
    caramel02,
} from './interlude.module.scss';

import AbsoluteImage from './absolute-image';

interface IInterludeProps {
    className?: string;
}

const Interlude: React.FC<IInterludeProps> = ({ className = '' }) => {
    const { t } = useI18next();

    return (
        <div className={`${container} ${className}`}>
            <AbsoluteImage className={caramel02} image="caramel" />
            <div className={imageBox}>
                <AbsoluteImage className={chocolateSplash} image="chocolate-splash-one" />
            </div>
            <div className={titleBox}>
                <h2 className={title}>
                    <span className={icon}>
                        <StaticImage
                            src="../../assets/images/soplica-wedel/logo-wedel.png"
                            alt="Wedel"
                            quality={100}
                        />
                    </span>
                    <br />
                    {t('wedel.interlude.inDesserts')}
                    <br />
                    {t('wedel.interlude.inDrinks')}
                    <br />
                    {t('wedel.interlude.hot')}
                </h2>
                <AbsoluteImage className={chocolate} image="chocolate" />
                <AbsoluteImage className={caramel01} image="caramel" />
            </div>
            <div className={imageBox}>
                <AbsoluteImage className={caramelSplash} image="caramel-splash-one" />
            </div>
        </div>
    );
};

export default Interlude;
