import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { container, item, chocolate, cocoa, chocolateBar } from './marquee-item.module.scss';
import ChocolateIcon from '../../assets/images/soplica-wedel/svg/chocolate.svg';
import CocoaIcon from '../../assets/images/soplica-wedel/svg/cocoa.svg';
import ChocolateBarIcon from '../../assets/images/soplica-wedel/svg/chocolate-bar.svg';

interface IMarqueeItemProps {
    className?: string;
}

const MarqueeItem: React.FC<IMarqueeItemProps> = ({ className = '' }) => {
    const { t } = useI18next();

    return (
        <div className={`${container} ${className}`}>
            <div className={item}>
                <p>{t('wedel.marquee.quality')}</p>
                <ChocolateIcon className={chocolate} />
            </div>
            <div className={item}>
                <p>{t('wedel.marquee.taste')}</p>
                <CocoaIcon className={cocoa} />
            </div>
            <div className={item}>
                <p>{t('wedel.marquee.pleasure')}</p>
                <ChocolateBarIcon className={chocolateBar} />
            </div>
        </div>
    );
};

export default MarqueeItem;
