import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    container,
    chocolateImage,
    marquee,
    serving,
    recipesSection,
    inspiration,
    caramelServing,
    wedelLogo,
    additionTwo,
} from './template.module.scss';

import { IPage } from '../models/page.model';
import { ImageDataLike } from '../models/image-data-like.model';
import { IWedelServing } from './components/serving-card';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IWedelRecipe } from './components/recipe-card';
import { getNodes } from '../utils/get-nodes';

import SEO from '../components/seo';
import Header from './components/header';
import Hero from './components/hero';
import Product, { IProductWedel } from './components/product';
import Interlude from './components/interlude';
import Marquee from './components/marquee';
import MarqueeItem from './components/marquee-item';
import Serving from './components/serving';
import Recipes, { IWedelRecipeOption } from './components/recipes';
import Inspiration from './components/inspiration';
import Footer from './components/footer';
import ServingDisclaimer from './components/serving-disclaimer';

interface ISoplicaWedelProps {
    readonly data: {
        page: IPage | null;
        allServingImg: IQueryAllResult<ImageDataLike>;
        allRecipeImg: IQueryAllResult<ImageDataLike>;
    };
}

const SoplicaWedel: React.FC<ISoplicaWedelProps> = ({ data }) => {
    const { page, allServingImg, allRecipeImg } = data;
    const { t, language } = useI18next();

    const caramelProduct: IProductWedel = {
        name: t('wedel.product.caramel'),
        subName: (
            <>
                {t('wedel.product.caramel.addition.1')}
                <span className={wedelLogo}>
                    <StaticImage
                        src="../assets/images/soplica-wedel/logo-wedel.png"
                        alt="Wedel"
                        quality={100}
                    />
                </span>
                <span className={additionTwo}>{t('wedel.product.caramel.addition.2')}</span>
            </>
        ),
        description: t('wedel.product.caramel.description'),
        serving: t('wedel.product.caramel.serving'),
        voltage: '15%',
        capacity: '500 ml',
        imgSrc:
            language === 'en'
                ? '/images/soplica-wedel-product-caramel-en.png'
                : '/images/soplica-wedel-product-caramel.png',
    };

    const chocolateProduct: IProductWedel = {
        name: t('wedel.product.chocolate'),
        subName: (
            <>
                {t('wedel.product.chocolate.addition.1')}
                <span className={wedelLogo}>
                    <StaticImage
                        src="../assets/images/soplica-wedel/logo-wedel.png"
                        alt="Wedel"
                        quality={100}
                    />
                </span>
                <span className={additionTwo}>{t('wedel.product.chocolate.addition.2')}</span>
            </>
        ),
        description: t('wedel.product.chocolate.description'),
        serving: t('wedel.product.chocolate.serving'),
        voltage: '15%',
        capacity: '500 ml',
        imgSrc:
            language === 'en'
                ? '/images/soplica-wedel-product-chocolate-en.png'
                : '/images/soplica-wedel-product-chocolate.png',
    };

    const servingImages = getNodes(allServingImg);
    const servings: IWedelServing[] = [
        { image: servingImages[0], type: 'shot', text: t('wedel.serving.shot') },
        { image: servingImages[1], type: 'drink', text: t('wedel.serving.drink') },
        { image: servingImages[2], type: 'dessert', text: t('wedel.serving.dessert') },
        { image: servingImages[3], type: 'coffee', text: t('wedel.serving.coffee') },
        { image: servingImages[4], type: 'chocolate', text: t('wedel.serving.chocolate') },
    ];

    const recipeOptions: IWedelRecipeOption[] = [
        { label: t('wedel.recipe.all'), value: 'all' },
        { label: t('wedel.recipe.drink'), value: 'drink' },
        { label: t('wedel.recipe.shot'), value: 'shot' },
        { label: t('wedel.recipe.dessert'), value: 'dessert' },
        { label: t('wedel.recipe.coffee'), value: 'coffee' },
        { label: t('wedel.recipe.chocolate'), value: 'chocolate' },
    ];

    const recipeImages = getNodes(allRecipeImg);
    const recipes: IWedelRecipe[] = [
        {
            name: t('wedel.recipes.caramel.queen.name'),
            type: 'drink',
            image: recipeImages[0],
            ingredients: [
                t('wedel.recipes.caramel.queen.ingredient.1'),
                t('wedel.recipes.caramel.queen.ingredient.2'),
                t('wedel.recipes.caramel.queen.ingredient.3'),
                t('wedel.recipes.caramel.queen.ingredient.4'),
            ],
            preparation: t('wedel.recipes.caramel.queen.preparation'),
            garnish: t('wedel.recipes.caramel.queen.garnish'),
        },
        {
            name: t('wedel.recipes.coffee.awakening.name'),
            type: 'drink',
            image: recipeImages[8],
            ingredients: [
                t('wedel.recipes.coffee.awakening.ingredient.1'),
                t('wedel.recipes.coffee.awakening.ingredient.2'),
                t('wedel.recipes.coffee.awakening.ingredient.3'),
                t('wedel.recipes.coffee.awakening.ingredient.4'),
            ],
            preparation: t('wedel.recipes.coffee.awakening.preparation'),
            garnish: t('wedel.recipes.coffee.awakening.garnish'),
        },
        {
            name: t('wedel.recipes.surprise.name'),
            type: 'drink',
            image: recipeImages[1],
            ingredients: [
                t('wedel.recipes.surprise.ingredient.1'),
                t('wedel.recipes.surprise.ingredient.2'),
                t('wedel.recipes.surprise.ingredient.3'),
                t('wedel.recipes.surprise.ingredient.4'),
            ],
            preparation: t('wedel.recipes.surprise.preparation'),
            garnish: t('wedel.recipes.surprise.garnish'),
        },
        {
            name: t('wedel.recipes.delight.name'),
            type: 'drink',
            image: recipeImages[7],
            ingredients: [
                t('wedel.recipes.delight.ingredient.1'),
                t('wedel.recipes.delight.ingredient.2'),
                t('wedel.recipes.delight.ingredient.3'),
                t('wedel.recipes.delight.ingredient.4'),
            ],
            preparation: t('wedel.recipes.delight.preparation'),
            garnish: t('wedel.recipes.delight.garnish'),
        },
        {
            name: t('wedel.recipes.whisper.name'),
            type: 'drink',
            image: recipeImages[9],
            ingredients: [
                t('wedel.recipes.whisper.ingredient.1'),
                t('wedel.recipes.whisper.ingredient.2'),
                t('wedel.recipes.whisper.ingredient.3'),
                t('wedel.recipes.whisper.ingredient.4'),
            ],
            preparation: t('wedel.recipes.whisper.preparation'),
            garnish: t('wedel.recipes.whisper.garnish'),
        },
        {
            name: t('wedel.recipes.coconut.name'),
            type: 'shot',
            image: recipeImages[11],
            ingredients: [
                t('wedel.recipes.coconut.ingredient.1'),
                t('wedel.recipes.coconut.ingredient.2'),
            ],
            preparation: t('wedel.recipes.coconut.preparation'),
            garnish: t('wedel.recipes.coconut.garnish'),
        },
        {
            name: t('wedel.recipes.moment.name'),
            type: 'shot',
            image: recipeImages[10],
            ingredients: [
                t('wedel.recipes.moment.ingredient.1'),
                t('wedel.recipes.moment.ingredient.2'),
            ],
            preparation: t('wedel.recipes.moment.preparation'),
            garnish: t('wedel.recipes.moment.garnish'),
        },
        {
            name: t('wedel.recipes.kiss.name'),
            type: 'shot',
            image: recipeImages[2],
            ingredients: [
                t('wedel.recipes.kiss.ingredient.1'),
                t('wedel.recipes.kiss.ingredient.2'),
            ],
            preparation: t('wedel.recipes.kiss.preparation'),
            garnish: t('wedel.recipes.kiss.garnish'),
        },
        {
            name: t('wedel.recipes.caramel.name'),
            type: 'shot',
            image: recipeImages[16],
            ingredients: [
                t('wedel.recipes.caramel.ingredient.1'),
                t('wedel.recipes.caramel.ingredient.2'),
            ],
            preparation: t('wedel.recipes.caramel.preparation'),
            garnish: t('wedel.recipes.caramel.garnish'),
            additionalLabel: t('wedel.recipes.caramel.label'),
            additionalContent: t('wedel.recipes.caramel.content'),
            caution: t('wedel.recipes.caramel.caution'),
        },
        {
            name: t('wedel.recipes.fantasy.name'),
            type: 'dessert',
            image: recipeImages[13],
            ingredients: [
                t('wedel.recipes.fantasy.ingredient.1'),
                t('wedel.recipes.fantasy.ingredient.2'),
                t('wedel.recipes.fantasy.ingredient.3'),
                t('wedel.recipes.fantasy.ingredient.4'),
                t('wedel.recipes.fantasy.ingredient.5'),
            ],
            preparation: t('wedel.recipes.fantasy.preparation'),
            garnish: t('wedel.recipes.fantasy.garnish'),
        },
        {
            name: t('wedel.recipes.banana.name'),
            type: 'dessert',
            image: recipeImages[4],
            ingredients: [
                t('wedel.recipes.banana.ingredient.1'),
                t('wedel.recipes.banana.ingredient.2'),
                t('wedel.recipes.banana.ingredient.3'),
                t('wedel.recipes.banana.ingredient.4'),
                t('wedel.recipes.banana.ingredient.5'),
            ],
            preparation: t('wedel.recipes.banana.preparation'),
            garnish: t('wedel.recipes.banana.garnish'),
        },
        {
            name: t('wedel.recipes.pistachio.name'),
            type: 'dessert',
            image: recipeImages[12],
            ingredients: [
                t('wedel.recipes.pistachio.ingredient.1'),
                t('wedel.recipes.pistachio.ingredient.2'),
                t('wedel.recipes.pistachio.ingredient.3'),
                t('wedel.recipes.pistachio.ingredient.4'),
                t('wedel.recipes.pistachio.ingredient.5'),
            ],
            preparation: t('wedel.recipes.pistachio.preparation'),
            garnish: t('wedel.recipes.pistachio.garnish'),
        },
        {
            name: t('wedel.recipes.pleasure.name'),
            type: 'dessert',
            image: recipeImages[3],
            ingredients: [
                t('wedel.recipes.pleasure.ingredient.1'),
                t('wedel.recipes.pleasure.ingredient.2'),
                t('wedel.recipes.pleasure.ingredient.3'),
            ],
            preparation: t('wedel.recipes.pleasure.preparation'),
            garnish: t('wedel.recipes.pleasure.garnish'),
        },
        {
            name: t('wedel.recipes.fog.name'),
            type: 'coffee',
            image: recipeImages[15],
            ingredients: [
                t('wedel.recipes.fog.ingredient.1'),
                t('wedel.recipes.fog.ingredient.2'),
                t('wedel.recipes.fog.ingredient.3'),
            ],
            preparation: t('wedel.recipes.fog.preparation'),
            garnish: t('wedel.recipes.fog.garnish'),
        },
        {
            name: t('wedel.recipes.dream.name'),
            type: 'coffee',
            image: recipeImages[6],
            ingredients: [
                t('wedel.recipes.dream.ingredient.1'),
                t('wedel.recipes.dream.ingredient.2'),
                t('wedel.recipes.dream.ingredient.3'),
            ],
            preparation: t('wedel.recipes.dream.preparation'),
        },
        {
            name: t('wedel.recipes.romance.name'),
            type: 'chocolate',
            image: recipeImages[14],
            ingredients: [
                t('wedel.recipes.romance.ingredient.1'),
                t('wedel.recipes.romance.ingredient.2'),
                t('wedel.recipes.romance.ingredient.3'),
                t('wedel.recipes.romance.ingredient.4'),
            ],
            preparation: t('wedel.recipes.romance.preparation'),
            garnish: t('wedel.recipes.romance.garnish'),
        },
        {
            name: t('wedel.recipes.warmup.name'),
            type: 'chocolate',
            image: recipeImages[5],
            ingredients: [
                t('wedel.recipes.warmup.ingredient.1'),
                t('wedel.recipes.warmup.ingredient.2'),
                t('wedel.recipes.warmup.ingredient.3'),
                t('wedel.recipes.warmup.ingredient.4'),
            ],
            preparation: t('wedel.recipes.warmup.preparation'),
            garnish: t('wedel.recipes.warmup.garnish'),
        },
    ];

    return (
        <>
            <SEO meta={page?.meta} />
            <div className={container}>
                <Header />
                <Hero />
                <Product id="liquors" product={chocolateProduct} imageClassName={chocolateImage} />
                <Interlude />
                <Product product={caramelProduct} isReverse={true}>
                    <ServingDisclaimer className={caramelServing} />
                </Product>
                <Marquee className={marquee}>
                    <MarqueeItem />
                </Marquee>
                <Serving id="serving" className={serving} servingCards={servings} />
                <Recipes
                    id="recipes"
                    className={recipesSection}
                    options={recipeOptions}
                    recipes={recipes}
                />
                <Inspiration className={inspiration} />
                <Footer />
            </div>
        </>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
        allServingImg: allFile(
            filter: {
                relativePath: {
                    in: [
                        "soplica-wedel/serving-01-shot.jpg"
                        "soplica-wedel/serving-02-drink.jpg"
                        "soplica-wedel/serving-03-dessert.jpg"
                        "soplica-wedel/serving-04-coffee.jpg"
                        "soplica-wedel/serving-05-chocolate.jpg"
                    ]
                }
            }
            sort: { fields: relativePath, order: ASC }
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED, quality: 100, width: 500)
                    }
                }
            }
        }
        allRecipeImg: allFile(
            filter: {
                relativePath: {
                    in: [
                        "soplica-wedel/recipe-01-caramel-queen.jpg"
                        "soplica-wedel/recipe-02-sweet-salty-surprise.jpg"
                        "soplica-wedel/recipe-03-express-kiss.jpg"
                        "soplica-wedel/recipe-04-frosty-pleasure.jpg"
                        "soplica-wedel/recipe-05-banana-fantasy.jpg"
                        "soplica-wedel/recipe-06-caramel-warming.jpg"
                        "soplica-wedel/recipe-07-latte-caramel-dream.jpg"
                        "soplica-wedel/recipe-08-chocolate-delight.jpg"
                        "soplica-wedel/recipe-09-coffee-awakening.jpg"
                        "soplica-wedel/recipe-10-mint-whisper.jpg"
                        "soplica-wedel/recipe-11-chocolate-moment.jpg"
                        "soplica-wedel/recipe-12-coconut-fluff.jpg"
                        "soplica-wedel/recipe-13-magic-pistachio.jpg"
                        "soplica-wedel/recipe-14-nutty-fantasy.jpg"
                        "soplica-wedel/recipe-15-romance-in-marshmallows.jpg"
                        "soplica-wedel/recipe-16-latte-chocolate-mist.jpg"
                        "soplica-wedel/recipe-17-hot-caramel.jpg"
                    ]
                }
            }
            sort: { fields: relativePath, order: ASC }
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED, quality: 100, width: 500)
                    }
                }
            }
        }
    }
`;

export default SoplicaWedel;
