import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    container,
    inner,
    image,
    ratio,
    content,
    icon,
    title,
    chocolate,
    caramel,
    imageBox,
    serving,
} from './hero.module.scss';
import { grid } from '../../styles/grid.module.scss';

import RatioImage from '../../components/atoms/ratio-image';
import Button from './button';
import AbsoluteImage from './absolute-image';
import ServingDisclaimer from './serving-disclaimer';

interface IHeroProps {
    className?: string;
}

const Hero: React.FC<IHeroProps> = ({ className = '' }) => {
    const { t } = useI18next();

    return (
        <div className={`${container} ${grid} ${className}`}>
            <div className={inner}>
                <AbsoluteImage className={chocolate} image="chocolate" />
                <AbsoluteImage className={caramel} image="caramel" />
                <div className={content}>
                    <h1 className={title}>
                        <span>
                            {t('wedel.hero.1')} <br /> {t('wedel.hero.2')}
                        </span>
                        <span className={icon}>
                            <StaticImage
                                src="../../assets/images/soplica-wedel/logo-wedel.png"
                                alt="Wedel"
                                quality={100}
                            />
                        </span>
                        <span>{t('wedel.hero.3')}</span>
                    </h1>
                    <Button as="gatsbyLink" to="#liquors">
                        {t('wedel.hero.button')}
                    </Button>
                </div>
                <div className={imageBox}>
                    <RatioImage
                        className={image}
                        ratioClass={ratio}
                        image={undefined}
                        src="/images/soplica-wedel-hero-bottles.png"
                    />
                    <ServingDisclaimer className={serving} />
                </div>
            </div>
        </div>
    );
};

export default Hero;
