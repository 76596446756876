module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Soplica","short_name":"Soplica","start_url":"/","background_color":"#7C152B","theme_color":"#7C152B","display":"standalone","icon":"/var/www/soplica.pl/releases/14/frontend/src/assets/images/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"70bfcfd26033a6fad5351192fb53cf4e"},
    },{
      plugin: require('../plugins/gatsby-plugin-ap-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","defaultLanguage":"pl","languages":["pl","en"],"siteUrl":"https://soplica.pl","redirect":false,"pagination":{"translations":{"pl":"strona","en":"page"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
