import React, { useState } from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    container,
    inner,
    header,
    title,
    list,
    item,
    card,
    chocolateSplash01,
    chocolateSplash02,
    caramelSplash,
    caramel,
    backlight,
} from './recipes.module.scss';
import { grid } from '../../styles/grid.module.scss';
import { TWedelServingType } from './serving-card';

import PillSwitcher, { IPillSwitcherProps } from './pill-switcher';
import RecipeCard, { IWedelRecipe } from './recipe-card';
import AbsoluteImage from './absolute-image';
import Backlight from './backlight';

export interface IWedelRecipeOption {
    label: string;
    value: TWedelServingType | 'all';
}

interface IRecipesProps {
    className?: string;
    recipes: IWedelRecipe[];
    options: IWedelRecipeOption[];
    id?: string;
}

const Recipes: React.FC<IRecipesProps> = ({ className, recipes, options, id }) => {
    const { t } = useI18next();
    const [activeOption, setActiveOption] = useState(options[0]);

    const handleChange: IPillSwitcherProps['onChange'] = (option) => {
        setActiveOption(option as IWedelRecipeOption);
    };

    return (
        <div id={id} className={`${container} ${grid} ${className}`}>
            <div className={inner}>
                <Backlight className={backlight} />
                <AbsoluteImage image="chocolate-splash-three" className={chocolateSplash01} />
                <AbsoluteImage image="chocolate-splash-three" className={chocolateSplash02} />
                <AbsoluteImage image="caramel-splash-three" className={caramelSplash} />
                <AbsoluteImage image="caramel" className={caramel} />
                <div className={header}>
                    <h2 className={title}>{t('wedel.recipes.title')}</h2>
                    <PillSwitcher
                        options={options}
                        activeOption={activeOption}
                        onChange={handleChange}
                    />
                </div>
                <ul className={list}>
                    {recipes
                        .filter((recipe) => {
                            return (
                                activeOption.value === 'all' || recipe.type === activeOption.value
                            );
                        })
                        .map((recipe, index) => {
                            return (
                                <li key={`recipe-item-${index}`} className={item}>
                                    <RecipeCard className={card} recipe={recipe} />
                                </li>
                            );
                        })}
                </ul>
            </div>
        </div>
    );
};

export default Recipes;
